<template>
  <div>
    <header class="fd-app-mode-header">
      <div
        class="fd-app-mode-header-bg"
        :style="{ backgroundImage: 'url(' + fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      >
        <div class="fd-app-mode-header-overlay">
          <div class="container">
            <div class="fd-app-mode-header-overlay-content">
              <div class="fd-app-mode-header-overlay-content-title">
                <h1>Checkout - {{ fastPlataforma.nome_plataforma == "admin" ? "FastEAD" : fastPlataforma.nome_plataforma }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="fd-app-wrap mt-4">
      <div
        v-if="fastSessaoCheckoutFinal.venda"
        class="container"
      >
        <div class="row">
          <div
            v-if="fastSessaoCheckoutFinal.status == 'paid'"
            class="col-12 text-center"
          >
            <h2>Muito obrigado!</h2>
            <h3>Sua compra foi realizada com sucesso</h3>
            <p>Em instantes seu(s) curso(s) serão liberados para suas plataformas.</p>
          </div>
          <div
            v-else-if="fastSessaoCheckoutFinal.status == 'bank_slip:pending'"
            class="col-12"
          >
            <div class="row">
              <div
                v-if="fastSessaoCheckoutFinal.forma_pagamento == 'credit_card'"
                class="col-12 text-center"
              >
                <h2 class="text-warning">
                  Pagamento pendente!
                </h2>
                <h3>Aguarde o processamento da sua compra.</h3>
                <p>Você receberá um email após a confirmação do pagamento.</p>
              </div>
              <div
                v-else-if="fastSessaoCheckoutFinal.forma_pagamento == 'bank_slip'"
                class="col-12 text-center"
              >
                <h2 class="text-warning">
                  Pagamento pendente!
                </h2>
                <h3>Assim que recebermos a confirmação do pagamento você será notificado.</h3>
                <a
                  target="_blank"
                  class="btn btn-primary"
                  :href="fastSessaoCheckoutFinal.url"
                >Exibir boleto</a>
              </div>
            </div>
          </div>
          <div
            v-else-if="fastSessaoCheckoutFinal.status == 'rejected'"
            class="col-12 text-center"
          >
            <h2 class="text-danger">
              Pagamento rejeitado!
            </h2>
            <h6>{{ fastSessaoCheckoutFinal.mensagem }}</h6>
          </div>
        </div>
        <h1 />
      </div>
    </div>
    <!-- Modals -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import headerCursoInfo from "@/assets/images/header-curso-info.jpg";

export default {
  name: "LojaVirtualCarrinhoFinal",
  mixins: [methods],
  data: function () {
    return {
      headerCursoInfo,
      //fastPlataforma
      First_name: "",
      Last_name: "",
      fastPlataforma: {
        banner_plataforma: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        url_plataforma: "",
      },
      fastSessaoCheckoutFinal: {
        erro: "",
        status: "",
        url: "",
        mensagem: "",
      },
    };
  },
  mounted() {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;

      this.getPlataformaData();
    }
  },
  methods: {
    async getPlataformaData() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma/lista_detalhes_cliente?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataforma = obj[0];
        }
        if (this.getFastSessao(settings.fastSessaoCheckoutFinal)) {
          this.fastSessaoCheckoutFinal = JSON.parse(
            this.getFastSessao(settings.fastSessaoCheckoutFinal)
          );
          // Informa ao component pai para interromper o loading
          this.$store.state.fastCarregando = false;
        } else {
          window.location.href =
            "/loja-virtual/" + this.$store.state.fastPermissoes.id_plataforma;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
